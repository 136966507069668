import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import GlobalProvider from './GlobalProvider';
import RouteWrapper from './pages';

const App = () => {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <RouteWrapper />
      </BrowserRouter>
      <Toaster />
    </GlobalProvider>
  );
};

export default App;
