import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { getLocalUser } from './utils';

const initialValue = () => {
  return {
    // 用户信息
    user: getLocalUser(),
    // 我的应用列表
    apps: null,
    // 系统应用列表
    templates: null,
    // 应用广场列表
    square: null,
    // 应用详情
    appDetail: null,
    // 用户已发布列表
    userAppList: null,
  };
};
const GlobalContext = createContext({});
function reducer(state, action) {
  switch (action.type) {
    case 'SET_INFO':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const GlobalProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, {}, () => {
    return initialValue();
  });
  const setInfo = useCallback((payload) => {
    dispatch({ type: 'SET_INFO', payload });
  }, []);
  // 验证是否登录，未登录重定向至登录页
  useEffect(() => {
    const loc = window.location;
    if (!state.user?.token && loc.pathname !== '/login') {
      loc.href = `/login?redirect=${encodeURIComponent(loc.pathname + loc.search)}`;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Provider value={{ ...state, setInfo }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must use in GlobalContext');
  }
  return context;
};
const withGlobalProvider = (Comp) => {
  return function With(props) {
    const state = useGlobalContext();
    const newState = { ...state, ...props };
    return <Comp {...newState} />;
  };
};

export { useGlobalContext, withGlobalProvider };
export default GlobalProvider;
