import { lazy } from 'react';

const AppLayout = lazy(() => import(/* webpackChunkName: "AppLayout" */ '@/components/layout/app'));
const AppView = lazy(() => import(/* webpackChunkName: "AppView" */ './appView'));
const AppBuilder = lazy(() => import(/* webpackChunkName: "AppBuilder" */ './appBuilder'));
const AppList = lazy(() => import(/* webpackChunkName: "AppList" */ './appList'));
const Library = lazy(() => import(/* webpackChunkName: "Library" */ './library'));
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ './profile'));
const User = lazy(() => import(/* webpackChunkName: "User" */ './user'));
const Credits = lazy(() => import(/* webpackChunkName: "Credits" */ './credits'));
const AppSquare = lazy(() => import(/* webpackChunkName: "AppSquare" */ './appSquare'));

const routes = [
  {
    path: '/',
    element: <AppLayout/>,
    children: [
      {
        index: true,
        element: <AppList />,
      },
      {
        path: '/apps',
        element: <AppList />,
      },
      {
        path: '/apps/:id',
        element: <AppBuilder />,
      },
      {
        path: '/square',
        element: <AppSquare />,
      },
      {
        path: '/library',
        element: <Library />,
      },
      {
        path: '/credits',
        element: <Credits />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '/user/:id',
    element: <User />,
  },
  {
    path: 'view/:id',
    element: <AppView />,
  },
];

export default routes;
