// 主应用路由
import Loading from '@/components/common/loading';
import { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import appRoutes from './app';

const Login = lazy(() => import(/* webpackChunkName: "Login" */ './login'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './NotFound'));
const TrialList = lazy(() => import(/* webpackChunkName: "ApplyTrial" */ './admin/trial'));
const allRoutes = appRoutes.concat([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/apply/trial/e9ed9b5c-c8a1-49d8-9e48-cfec93174bcf',
    element: <TrialList />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
const syncRouter = (data) => {
  const tmpRoutes = [];
  data.forEach(route => {
    tmpRoutes.push({
      ...route,
      element: (
        <Suspense fallback={<Loading />}>
          {route.element}
        </Suspense>
      ),
      children: route.children && syncRouter(route.children),
    });
  });
  return tmpRoutes;
};
const routes = syncRouter(allRoutes);
const RouteWrapper = () => {
  const elements = useRoutes(routes);
  return elements;
};
export default RouteWrapper;
