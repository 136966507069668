import config from '@/config';
import toast from 'react-hot-toast';

export const getClassName = (name) => {
  if (name instanceof Array) {
    return name.map(v => `${config.prefix}-${v}`).join(' ');
  }
  return `${config.prefix}-${name}`;
};

export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getNumStr = (num) => {
  if (num >= 1000 && num < Math.pow(10, 6)) {
    const numStr = (num / 1000).toFixed(1);
    return `${numStr}K`;
  }
  if (num >= Math.pow(10, 6) && num < Math.pow(10, 9)) {
    const numStr = (num / Math.pow(10, 6)).toFixed(1);
    return `${numStr}M`;
  }
  if (num >= Math.pow(10, 9)) {
    const numStr = (num / Math.pow(10, 9)).toFixed(1);
    return `${numStr}B`;
  }
  return num;
};

export const getTimeStr = (timeStr) => {
  const nowTime = Date.now();
  const curDate = new Date(timeStr);
  if (curDate.toString === 'Invalid Date') return null;
  const dValue = (nowTime - curDate.getTime()) / 1000;
  if (dValue <= 60) {
    // return 'a few sec ago';
    return '1 分钟内';
  }
  let mins = Math.floor(dValue / 60);
  if (mins < 60) {
    // return mins === 1 ? 'an min ago' : `${mins} mins ago`;
    return `${mins} 分钟前`;
  }
  let hour = Math.floor(dValue / (60 * 60));
  if (hour < 24) {
    // return hour === 1 ? 'an hour ago' : `${hour} hours ago`;
    return `${hour} 小时前`;
  }
  let day = Math.floor(dValue / (60 * 60 * 24));
  if (day < 30) {
    // return day === 1 ? 'an day ago' : `${day} days ago`;
    return `${day} 天前`;
  }
  const month = Math.floor(dValue / (60 * 60 * 24 * 30));
  if (month < 12) {
    // return month === 1 ? 'an mth ago' : `${month} mths ago`;
    return `${month} 月前`;
  }
  const year = Math.floor(dValue / (60 * 60 * 24 * 360));
  // return year === 1 ? 'an year ago' : `${year} years ago`;
  return `${year} 年前`;
};

export const getContrastColor = (hexColor) => {
  if (hexColor.indexOf('#') === 0) {
    hexColor = hexColor.slice(1);
  }
  // convert hex to RGB
  let r = parseInt(hexColor.slice(0, 2), 16);
  let g = parseInt(hexColor.slice(2, 4), 16);
  let b = parseInt(hexColor.slice(4, 6), 16);
  // calculate color brightness
  let brightness = (r * 299 + g * 587 + b * 114) / 1000;
  // return white or black depending on brightness
  return brightness > 128 ? '#000000' : '#FFFFFF';
};

const url = /https?:\/\/((\w(-w+)?)+\.)+(\w(-w+)?)+(:[0-9]+)?([/?#][^\s"']+)?/;

export const urlValidator = (str) => {
  if (str && !url.test(str)) {
    return {
      message: '请输入正确的url地址',
    };
  }
  return null;
};

export const getLocalUser = () => {
  const user = localStorage.getItem('user');
  try {
    return JSON.parse(user);
  } catch (e) {
    return {};
  }
};

export const baseCopy = (textContent) => {
  try {
    const textInput = document.createElement('textarea');
    textInput.value = textContent;
    document.body.appendChild(textInput);
    textInput.select();
    document.execCommand('copy');
    textInput.remove();
    return Promise.resolve().then(() => {
      toast.success('已成功复制到剪贴板');
    });
  } catch (e) {
    return Promise.reject();
  }
};

export const copyText = (dom) => {
  const isHttp = window.location.protocol === 'http:' && window.location.hostname !== 'localhost';
  const textContent = typeof dom === 'string' ? dom : dom.innerText;
  if (!isHttp && navigator.clipboard) {
    return navigator.clipboard.writeText(textContent).then(() => {
      toast.success('已成功复制到剪贴板');
    }).catch((err) => {
      return baseCopy(textContent);
    });
  }
  return baseCopy(textContent);
};

export const needMore = (el) => {
  return el.scrollTop + el.clientHeight * 2 > el.scrollHeight;
};
