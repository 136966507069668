import React, { useEffect, useState } from 'react';
import Icon from '../icon';
import './index.scss';

const baseClass = 'npt-loading';
// width height className
const Loading = (props) => {
  const { size, wrapperClassName } = props;
  const [className, setClassName] = useState(baseClass);
  const options = {};
  useEffect(() => {
    const tmpArr = [baseClass];
    if (wrapperClassName) {
      tmpArr.push(wrapperClassName);
    }
    setClassName(tmpArr.join(' '));
  }, [wrapperClassName]);
  if (size !== undefined) {
    options.fontSize = `${size}px`;
  }
  return (
    <div className={className} style={options}>
      <Icon type="loading"/>
    </div>
  );
};
export default Loading;
